import { LayoutDataSource } from '@n7-frontend/core';
import { HttpHeaders } from '@angular/common/http';
import { CookieService } from 'ngx-cookie-service';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';

export class PersonalLayoutDS extends LayoutDataSource {
  private communication;

  private router: Router;

  private cookieService: CookieService;

  public activeTab = 'completed';

  public routerNotifier = new Subject<string>();

  public searchVal = '';

  private PROJECT_STATUS = {
    0: 'completed',
    1: 'pending',
    2: 'declined',
  }

  public projects = {
    completed: [],
    pending: [],
  }

  onInit(payload) {
    this.communication = payload.communication;
    this.cookieService = payload.cookieService;
    this.router = payload.router;
    this.initProjects();
  }

  private initProjects() {
    const token = (this.cookieService.check('RAMA-LOGIN')) ? this.cookieService.get('RAMA-LOGIN') : undefined;
    if (token !== undefined) {
      this.communication.request$('getUserProjects', {
        method: 'GET',
        params: {},
        httpOptions: {
          headers: new HttpHeaders({ Authorization: `Bearer ${token}` })
        },
        onError: (err) => {
          console.warn('err', err);
        }
      }).subscribe((response) => {
        this.projects = { completed: [], pending: [] };
        response.projects.forEach((el) => {
          switch (el.status) {
            case 0:
              el.statusString = 'Completato'; // TBR, usare translate
              el.isCompleted = true;
              el.visible = true;
              this.projects.completed.push(el);
              break;
            case 1:
            case 2:
              el.statusString = 'In Attesa'; // TBR, usare translate
              el.isCompleted = false;
              el.visible = true;
              this.projects.pending.push(el);
              break;
            default:
              break;
          }
        });
      });
    } else {
      this.routerNotifier.next('login');
    }
  }

  public search(val) {
    this.searchVal = val.target.value;
    if (this.searchVal === '') {
      this.projects.completed.forEach((el) => {
        el.visible = true;
      });
      this.projects.pending.forEach((el) => {
        el.visible = true;
      });
    } else {
      this.projects.completed.forEach((el) => {
        if (el.name.toLowerCase().indexOf(this.searchVal) === -1) {
          el.visible = false;
        } else {
          el.visible = true;
        }
      });
      this.projects.pending.forEach((el) => {
        if (el.name.toLowerCase().indexOf(this.searchVal) === -1) {
          el.visible = false;
        } else {
          el.visible = true;
        }
      });
    }
  }
}
