import { QuoteLayoutDS } from './quote-layout.ds';
import { QuoteLayoutEH } from './quote-layout.eh';
import { DonutChartDS } from '../../data-sources/donut-chart.ds';
import * as DS from '../../data-sources';
import * as EH from '../../event-handlers';

export const QuoteLayoutConfig = {
  layoutId: 'rama-quote-layout',
  widgets: [
    { id: 'donut-chart', dataSource: DonutChartDS }
  ],
  layoutDS: QuoteLayoutDS,
  layoutEH: QuoteLayoutEH,
  widgetsDataSources: DS,
  widgetsEventHandlers: EH,
  layoutOptions: {}
};
