import { LayoutDataSource } from '@n7-frontend/core';
import SignupConfig from '../../config/global/signup.config';

export class LoginLayoutDS extends LayoutDataSource {
  private router;

  public currentScreen = 'signup';

  public modal = {
    show: false,
    msg: ''
  }

  hero_mock = {
    title: 'Registrati',
    text: 'Crea un nuovo account Rama inserendo i tuoi dati personali. Scegli la tua e-mail e la tua password. Ti suggeriamo di leggere i nostri <a href="#">consigli di sicurezza</a>.',
    backgroundImage: 'assets/login-layout/login-image.jpg',
  };

  onInit(payload) {
    this.router = payload.router;
  }

  initScreen() {
    const r = SignupConfig.pathToScreen[this.router.url];
    if (r === undefined) return 'login'; return r;
  }
}
