import { ConfigurationService, MainStateService } from '@n7-frontend/boilerplate';
import { EventHandler } from '@n7-frontend/core';
import { CookieService } from 'ngx-cookie-service';

export class LoginLayoutEH extends EventHandler {
  private communication;

  private cookieService: CookieService;

  private configuration: ConfigurationService;

  private mainState: MainStateService;

  public listen() {
    this.innerEvents$.subscribe(({ type, payload }) => {
      switch (type) {
        case 'rama-login-layout.init':
          this.dataSource.onInit(payload);
          this.communication = payload.communication;
          this.cookieService = payload.cookieService;
          this.configuration = payload.configuration;
          this.mainState = payload.mainState;
          this.emitOuter('initscreen', this.dataSource.initScreen());
          break;
        case 'rama-login-layout.modal-close':
          this.emitGlobal('navigate', {
            handler: 'router',
            path: ['/'],
          });
          break;
        default:
          // console.log(type, payload);
          break;
      }
    });

    this.outerEvents$.subscribe(({ type, payload }) => {
      switch (type) {
        case 'signup.login':
          this.login(payload);
          break;
        case 'signup.register':
          this.register(payload);
          break;
        default:
          // console.log(type, payload);
          break;
      }
    });
  }

  login(payload) {
    this.communication.request$('userLogin', {
      method: 'POST',
      params: payload,
      onError: (err) => {
        if (err.status === 401) {
          console.warn('Login errato');
          this.emitOuter('error_login', {
            email: 'Nome utente e/o password sbagliata',
            password: 'Nome utente e/o password sbagliata',
          });
        }
      }
    }).subscribe((response) => {
      this.cookieService.set('groups', response.data.groups, 7);
      this.cookieService.set('api_key', response.data.api_key, 7);
      this.cookieService.set('email', response.email, 7);
      this.cookieService.set('RAMA-LOGIN', response.jwt, 7);
      const topbar = this.configuration.get('header');
      topbar.user = {
        name: 'Area personale',
        anchor: { href: '/personal' }
      };
      topbar.buttons = [{text: "Esci", anchor: {href:"/logout"}, classes: "n7-btn"}];
      this.mainState.update('header', topbar);
      this.emitGlobal('navigate', {
        handler: 'router',
        path: ['personal'],
      });
    });
  }

  register(payload) {
    this.communication.request$('userRegister', {
      method: 'POST',
      params: payload,
      onError: (err) => {
        if (err.status === 403) {
          console.warn('Register denied');
          this.emitOuter('error_register', {
            email: 'Questa e-mail è già presente, effettua il login',
          });
        }
      }
    }).subscribe(() => {
      this.dataSource.modal = {
        show: true,
        msg: "La tua richiesta verrà presa in carico al più presto dagli amministratori.<br>Riceverai una email all'indirizzo specificato non appena il tuo account sarà attivo."
      };
    });
  }
}
