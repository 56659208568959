import { EventHandler } from '@n7-frontend/core';
import { Subject, timer } from 'rxjs';
import { Toggle } from 'types';
import { debounce, filter } from 'rxjs/operators';
import { RamaGridLayer } from 'src/app/models/rama-tileset';

const toggleState = (setting: Toggle): Toggle => {
  if (setting) {
    return Toggle.CLOSED;
  } return Toggle.OPEN;
};

export class MapLayoutEH extends EventHandler {
  private layoutCommunication$: Subject<any>

  /**
   * Subject that emits strings to search in openstreetmap
   */
  private searchString: Subject<string> = new Subject()

  /**
   * Deboucer for the search input
   */
  private searchStringListener = () => {
    this.searchString.pipe(debounce((d) => (d === '' ? timer(0) : timer(500)))).subscribe((string) => {
      this.dataSource.searchPlace(string);
    });
  }

  /**
   * Catch all events emitted by the Rama tileset module
   */
  private listenRamaLayer() {
    (this.dataSource.getWidgetDataSource('map').RamaLayer as RamaGridLayer).event$.subscribe((event) => {
      this.layoutCommunication$.next({
        type: `map-layout.${event.type}`,
        payload: event.payload
      });
    });
  }

  public listenLayouts() {
    this.layoutCommunication$.subscribe(({ type, payload }) => {
      switch (type) {
        case 'sidebar-layout.roaddelete': {
          const { RamaLayer } = this.dataSource.getWidgetDataSource('map');
          RamaLayer.removeSegmentById(payload.key);
          break;
        }
        case 'map-layout.autocompletekeyup':
          this.dataSource.onAutocompleteKeyUp$(payload.keyCode)
            .pipe(
              filter(({ isEnterKey, selectedResult }) => isEnterKey && selectedResult)
            )
            .subscribe(({ selectedResult }) => {
              this.emitInner('clickresult', selectedResult.data);
            });
          break;
        default:
          break;
      }
    });
  }

  public listen() {
    this.innerEvents$.subscribe(({ type, payload }) => {
      switch (type) {
        case 'rama-map-layout.init':
          this.dataSource.onInit(payload);
          this.layoutCommunication$ = payload.layoutCommunication$;
          // Start listening for user searches
          this.searchStringListener();
          this.listenRamaLayer();
          this.listenLayouts();
          break;
        case 'rama-map-layout.destroy':
          // this.dataSource.destroy();
          break;
        case 'rama-map-layout.togglesettings':
          this.dataSource.togglers.mapSettings = toggleState(this.dataSource.togglers.mapSettings);
          break;
        case 'rama-map-layout.settingsclick':
          this.dataSource.mapSettings = { ...this.dataSource.mapSettings, ...payload };
          this.emitOuter('settingsupdate', this.dataSource.mapSettings);
          break;
        case 'rama-map-layout.toggleinfo':
          this.dataSource.togglers.mapInfo = toggleState(this.dataSource.togglers.mapInfo);
          break;
        case 'rama-map-layout.togglelegend': {
          const { name, key } = payload;
          Object.keys(this.dataSource.togglers.legends).forEach((legend) => {
            if (legend !== name) this.dataSource.togglers.legends[legend] = Toggle.CLOSED;
            else this.dataSource.togglers.legends[legend] = Toggle.OPEN;
          });
          this.emitOuter('togglelegend', { key });
        } break;
        case 'rama-map-layout.searchplace':
          this.searchString.next(payload.input);
          this.dataSource.togglers.searchResults = Toggle.OPEN;
          break;
        case 'rama-map-layout.focusout':
          // clears the result list
          this.dataSource.togglers.searchResults = Toggle.CLOSED;
          break;
        case 'rama-map-layout.clickresult':
          this.dataSource.togglers.searchResults = Toggle.CLOSED;
          this.emitOuter('movemap', payload);
          break;
        default:
          console.warn('unhandled inner event of type', type);
          break;
      }
    });
  }
}
