import { EventHandler } from '@n7-frontend/core';

export class MapEH extends EventHandler {
  public listen() {
    this.innerEvents$.subscribe(({ type }) => {
      switch (type) {
        default:
          console.warn('unhandled inner event of type', type);
          break;
      }
    });
    this.outerEvents$.subscribe(({ type, payload }) => {
      switch (type) {
        case 'rama-map-layout.togglelegend':
          this.dataSource.RamaLayer.selectLegend(payload.key);
          break;
        case 'rama-map-layout.movemap':
          this.dataSource.moveMap(payload);
          break;
        case 'rama-map-layout.settingsupdate':
          this.dataSource.RamaLayer.changeDataset(payload.dataSet);
          break;
        default:
          // console.warn('unhandled outer event of type', type);
          break;
      }
    });
  }
}
