import { LayoutDataSource } from '@n7-frontend/core';
import {
  Toggle, Togglers, AutocompleteResponse, AutoCompleteKeyCodes
} from 'types';
import { CommunicationService, ConfigurationService } from '@n7-frontend/boilerplate';
import { Observable, of } from 'rxjs';
import { SelectionService } from 'src/app/services/selection-service';
import legendConfig from '../../../assets/legend-config';

export class MapLayoutDS extends LayoutDataSource {
  private communication: CommunicationService;

  private selectionService: SelectionService;

  private configuration: ConfigurationService;

  public legendConfig;

  public togglers: Togglers = {
    legends: {
      /** Irregolarità */
      iri: Toggle.OPEN,
      /** Profondità */
      mpd: Toggle.CLOSED,
      /** Deformazione */
      rutting: Toggle.CLOSED,
      /** Condizioni Pavimentazione */
      pci: Toggle.CLOSED
    },
    mapSettings: Toggle.CLOSED,
    mapInfo: Toggle.CLOSED,
    searchResults: Toggle.CLOSED,
  }

  public mapSettings = {
    dataSet: 'base',
  }

  /**
   * List of results shown under the search input,
   * resetting this value to an empty array will clear
   * the list.
   */
  public results: object[];

  private resultsActiveIndex = 0;

  onInit(payload) {
    this.selectionService = payload.selectionService;
    this.legendConfig = legendConfig;
    this.communication = payload.communication;
    this.configuration = payload.configuration;
    this.one('map').updateOptions({
      communication: this.communication,
      selection: this.selectionService
    });
    this.one('map').update({});
  }

  /**
   * Given a text string, search for a place
   * in openstreetmap, and update the list of
   * results.
   *
   * @param query Name of a place to search
   */
  public searchPlace = (query: string): any => {
    // if the string is empty don't search anything
    if (!query) return;
    // reset active result
    this.resultsActiveIndex = 0;
    this.communication.request$('autocomplete', {
      onError: (e) => console.error(e),
      urlParams: query
    }).subscribe((res: AutocompleteResponse) => {
      // create a list of results
      this.results = res.source.map((d) => ({
        name: (d.body.features[0] || {}).properties?.display_name,
        data: (d.body.features[0] || {})
      }));
    });
  }

  /**
   * autocomplete navigation keys handler
   *
   * @param query Name of a place to search
   */
  public onAutocompleteKeyUp$(keyCode: AutoCompleteKeyCodes): Observable<{
    isEnterKey: boolean;
    selectedResult?: any;
  }> {
    const resultsEl = document.querySelector('.rm-map__search-results');
    const totalResults = Array.isArray(this.results) ? this.results.length : 0;
    let isEnterKey = false;

    if (!resultsEl) {
      of({ isEnterKey });
    }

    switch (keyCode) {
      case AutoCompleteKeyCodes.DOWN:
        this.resultsActiveIndex = this.resultsActiveIndex >= totalResults
          ? 0
          : this.resultsActiveIndex + 1;
        break;
      case AutoCompleteKeyCodes.UP:
        this.resultsActiveIndex = this.resultsActiveIndex === 0
          ? totalResults
          : this.resultsActiveIndex - 1;
        break;
      case AutoCompleteKeyCodes.ENTER:
        isEnterKey = true;
        break;
      default:
        break;
    }

    return of({
      isEnterKey,
      selectedResult: this.resultsActiveIndex > 0
        ? this.results[this.resultsActiveIndex - 1]
        : null
    });
  }
}
