import { CommunicationService } from '@n7-frontend/boilerplate';
import { EventHandler } from '@n7-frontend/core';
import { HttpHeaders } from '@angular/common/http';
import { CookieService } from 'ngx-cookie-service';
import { SelectionService } from 'src/app/services/selection-service';

export class QuoteLayoutEH extends EventHandler {
  private layoutCommunication$;

  private communication: CommunicationService;

  private selectionService: SelectionService;

  private cookieService: CookieService;

  public listen() {
    this.innerEvents$.subscribe(({ type, payload }) => {
      switch (type) {
        case 'rama-quote-layout.init':
          this.dataSource.onInit(payload);
          this.layoutCommunication$ = payload.layoutCommunication$;
          this.selectionService = payload.selectionService;
          this.communication = payload.communication;
          this.cookieService = payload.cookieService;
          break;
        case 'rama-quote-layout.goback':
          this.layoutCommunication$.next({ type: 'quote-layout.goback', payload: {} });
          break;
        case 'rama-quote-layout.chart-move':
          if (payload === 'right') this.dataSource.handlePropertyChange(true);
          if (payload === 'left') this.dataSource.handlePropertyChange(false);
          break;
        default:
          break;
      }
    });

    /* this.outerEvents$.subscribe(({ type, payload }) => {
      switch (type) {
        default:
          break;
      }
    }); */
  }

  public formSave(formData) {
    // [TODO]: validation directly in template
    // but now we only care about the project name
    // and not in a fashion way
    if (formData.project.length < 1) {
      console.warn('Inserire il nome del progetto!');
      return;
    }

    const selectedSegments = this.selectionService.getValue();
    const arraySegments = [];
    Object.values(selectedSegments).forEach((el) => {
      arraySegments.push(el);
    });

    /* eslint-disable @typescript-eslint/camelcase */
    const params = {
      // [TODO]: dynamic selection for road characteristics
      roadbed_foundation: 'FOUNDATION_0',
      asphalt_type: 'ASPHALT_0',
      pavement_thickness: 'PAVEMENT_0',
      lane_markings: 'LANE_0',

      name: formData.project,
      selected_segments: {
        type: 'FeatureCollection',
        features: arraySegments,
      }
    };
    /* eslint-enable @typescript-eslint/camelcase */

    const token = (this.cookieService.check('RAMA-LOGIN')) ? this.cookieService.get('RAMA-LOGIN') : undefined;
    if (token !== undefined) {
      this.communication.request$('getUserProjects', {
        method: 'POST',
        params,
        httpOptions: {
          headers: new HttpHeaders({ Authorization: `Bearer ${token}` })
        },
        onError: (err) => {
          console.warn('err', err);
        }
      }).subscribe((response) => {
        console.info(response);
      });
    } else {
      this.emitGlobal('navigate', {
        handler: 'router',
        path: ['login'],
      });
    }
  }
}
