import { LayoutDataSource } from '@n7-frontend/core';
import { AppState } from 'types';

export class AppLayoutDS extends LayoutDataSource {
  private communication;

  public layoutState: AppState = AppState.MapOnly;

  onInit(payload) {
    this.communication = payload.communication;
  }
}
