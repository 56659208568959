import {
  Component, OnInit, OnDestroy, Input
} from '@angular/core';
import {
  AbstractLayout, CommunicationService, ConfigurationService, MainStateService
} from '@n7-frontend/boilerplate';
import { Subject } from 'rxjs';
import { CookieService } from 'ngx-cookie-service';
import { Router } from '@angular/router';
import { AboutLayoutConfig as config } from './about-layout.config';

@Component({
  selector: 'rama-about-layout',
  templateUrl: './about-layout.html'
})
export class AboutLayoutComponent extends AbstractLayout implements OnInit, OnDestroy {
  @Input() layoutCommunication$: Subject<any>;

  constructor(
    private communication: CommunicationService,
    private configuration: ConfigurationService,
    private cookieService: CookieService,
    private mainState: MainStateService,
    private router: Router
  ) {
    super(config);
  }

  protected initPayload() {
    const token = (this.cookieService.check('RAMA-LOGIN')) ? this.cookieService.get('RAMA-LOGIN') : undefined;
    if (token !== undefined) {
      const topbar = this.configuration.get('header');
      topbar.user = {
        name: 'Area personale',
        anchor: { href: '/personal' }
      };
      topbar.buttons = [{text: "Esci", anchor: {href:"/logout"}, classes: "n7-btn"}];
      this.mainState.update('header', topbar);
    }
    return {
      communication: this.communication,
      router: this.router,
      layoutCommunication$: this.layoutCommunication$,
      cookieService: this.cookieService
    };
  }

  ngOnInit() {
    this.onInit();
  }

  ngOnDestroy() {
    this.onDestroy();
  }
}
