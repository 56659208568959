import { MapLayoutDS } from './map-layout.ds';
import { MapLayoutEH } from './map-layout.eh';
import * as DS from '../../data-sources';
import * as EH from '../../event-handlers';

export const MapLayoutConfig = {
  layoutId: 'rama-map-layout',
  widgets: [
    { id: 'map' }
  ],
  layoutDS: MapLayoutDS,
  layoutEH: MapLayoutEH,
  widgetsDataSources: DS,
  widgetsEventHandlers: EH,
  layoutOptions: {}
};
