import { Routes } from '@angular/router';
import { Page404LayoutComponent } from '@n7-frontend/boilerplate';
import { AboutLayoutComponent } from './layouts/about-layout/about-layout';
import { AppLayoutComponent } from './layouts/app-layout/app-layout';
import { LoginLayoutComponent } from './layouts/login-layout/login-layout';
import { PersonalLayoutComponent } from './layouts/personal-layout/personal-layout';
import { ServicesLayoutComponent } from './layouts/services-layout/services-layout';
import { LogoutComponent } from './logout/logout.component';

export const APP_ROUTES: Routes = [
  { path: 'app', component: AppLayoutComponent },
  { path: 'signup', component: LoginLayoutComponent },
  { path: 'login', component: LoginLayoutComponent },
  { path: 'personal', component: PersonalLayoutComponent },
  { path: 'about', component: AboutLayoutComponent },
  { path: 'services', component: ServicesLayoutComponent },
  { path: 'logout', component: LogoutComponent },
  {
    path: '',
    redirectTo: '/app',
    pathMatch: 'full'
  },
  { path: '**', component: Page404LayoutComponent }
];
