import { LayoutDataSource } from '@n7-frontend/core';
import { CookieService } from 'ngx-cookie-service';
import { Router } from '@angular/router';

export class ServicesLayoutDS extends LayoutDataSource {
  private communication;

  private router: Router;

  private cookieService: CookieService;

  onInit(payload) {
    this.communication = payload.communication;
    this.cookieService = payload.cookieService;
    this.router = payload.router;
  }
}
