import { DataSource } from '@n7-frontend/core';

export class InnerTitleDS extends DataSource {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  protected transform(data) {
    return {
      icon: 'n7-icon-angle-up',
      title: {
        main: {
          text: 'SS12 rad',
          classes: 'bold'
        },
        secondary: {
          text: 'km 10 - km 12,5'
        }
      },
      actions: {
        buttons: [{
          anchor: { payload: 'menu' },
          icon: 'n7-icon-ellipsis-v'
        }]
      }
    };
  }
}
