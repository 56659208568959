/* eslint-disable @typescript-eslint/camelcase */
import { EventHandler } from '@n7-frontend/core';

export class ServicesLayoutEH extends EventHandler {
  public listen() {
    this.innerEvents$.subscribe(({ type, payload }) => {
      switch (type) {
        case 'rama-services-layout.init':
          this.dataSource.onInit(payload);
          break;
        default:
          // console.log(type, payload);
          break;
      }
    });

    // this.outerEvents$.subscribe(({ type, payload }) => {
    //   switch (type) {
    //     default:
    //       break;
    //   }
    // });
  }
}
