import { EventHandler } from '@n7-frontend/core';
import { Toggle, LayoutEvent, RoadSegment } from 'types';
import 'dayjs/locale/it';
import { SelectionService } from 'src/app/services/selection-service';

export class SidebarLayoutEH extends EventHandler {
  private layoutCommunication$;

  private selectionService: SelectionService;

  private listenSelections() {
    this.selectionService.selection$.subscribe((selection) => {
      // Update sidebar
      if (Object.keys(selection).length >= 1) {
        // if there is at least one selected item, open the sidebar
        this.layoutCommunication$.next({ type: 'sidebar-layout.opensidebar' });
      }
      this.dataSource.onSegmentClick(selection);
    });
  }

  public listenLayouts() {
    this.layoutCommunication$.subscribe(({ type }) => {
      switch (type) {
        case 'sidebar-layout.opensidebar':
        case 'map-layout.opensidebar':
          this.dataSource.togglers.activeState = Toggle.OPEN;
          break;
        case 'map-layout.closesidebar':
          this.dataSource.togglers.activeState = Toggle.CLOSED;
          break;
        default:
          break;
      }
    });
  }

  public listen() {
    this.innerEvents$.subscribe(({ type, payload }) => {
      switch (type) {
        case 'rama-sidebar-layout.init':
          this.dataSource.onInit(payload);
          this.layoutCommunication$ = payload.layoutCommunication$;
          this.selectionService = payload.selection;
          this.listenLayouts();
          this.listenSelections();
          break;
        case 'rama-sidebar-layout.endselection':
          this.layoutCommunication$.next({ type: 'sidebar-layout.finish', payload: {} });
          break;
        case 'rama-sidebar-layout.roadrename':
          this.toggleSegmentMenu(payload.key);
          break;
        case 'rama-sidebar-layout.roaddelete':
          this.toggleSegmentMenu(payload.key);
          this.selectionService.delete(payload.key);
          break;
        case 'rama-sidebar-layout.collapsesegment':
        case 'rama-sidebar-layout.collapseroad':
          // If the click comes from the menu button
          // collapse/expande menu
          if (this.isMenuButton(payload.event.target)) {
            break;
          } else if (payload.title) {
            if (this.dataSource.collapsedHeaders[payload.title]) {
              delete this.dataSource.collapsedHeaders[payload.title];
            } else {
              this.dataSource.collapsedHeaders[payload.title] = true;
            }
            // collapse/expande the segments' section.
          } else if (payload.segment) {
            const {
              id,
            } = (payload.segment as RoadSegment).properties;
            if (this.dataSource.collapsedSegments[id]) {
              delete this.dataSource.collapsedSegments[id];
            } else {
              this.dataSource.collapsedSegments[id] = true;
            }
          }
          break;
        case 'rama-sidebar-layout.modal-open':
          this.dataSource.galleryModal.thumbs = payload.thumbs;
          this.dataSource.galleryModal.name = payload.name;
          this.dataSource.galleryModal.segment = payload.segment;
          this.dataSource.galleryModal.open = true;
          break;
        case 'rama-sidebar-layout.modal-close':
          this.dataSource.galleryModal.active = '';
          this.dataSource.galleryModal.open = false;
          break;
        case 'rama-sidebar-layout.modal-thumb':
          this.dataSource.galleryModal.active = payload;
          break;
        default:
          console.warn('unhandled inner event of type', type);
          break;
      }
    });

    this.outerEvents$.subscribe(({ type, payload }: LayoutEvent) => {
      switch (type) {
        case 'inner-title.clickmenu':
          if (payload.type !== undefined && payload.type === 'open-modal') {
            this.emitInner('modal-open', payload);
          } else {
            this.toggleSegmentMenu(payload);
          }
          break;
        default:
          console.warn('unhandled outer event of type', type);
          break;
      }
    });
  }

  /**
   * Switches the state of a segmentMenu toggler (open/closed)
   * @param key A string
   */
  private toggleSegmentMenu(key: string) {
    if (this.dataSource.togglers.segmentMenu === key) {
      this.dataSource.togglers.segmentMenu = '';
    } else {
      this.dataSource.togglers.segmentMenu = key;
    }
  }

  public closeSidebar() {
    this.layoutCommunication$.next({ type: 'sidebar-layout.closesidebar' });
  }

  /**
   * Switches the state of a toggler (open/closed)
   * @param t A toggler
   */
  private toggleSwitch = (t: Toggle): Toggle => {
    if (t === Toggle.CLOSED) { return Toggle.OPEN; }
    return Toggle.CLOSED;
  }

  private isMenuButton(target: HTMLElement) {
    const { classList } = target;
    return classList.contains('n7-icon-ellipsis-v');
  }
}
