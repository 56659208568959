import { DataSource } from '@n7-frontend/core';

export class DonutChartDS extends DataSource {
  chartInstance;

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  protected transform(data) {
    return {
      containerId: 'donut-chart',
      libOptions: {
        chart: {
          type: 'donut',
          height: 100,
        },
        dataLabels: { enabled: false },
        colors: data.colors,
        fill: {
          colors: data.colors,
        },
        plotOptions: {
          pie: {
            donut: {
              size: '78%'
            }
          }
        },
        series: data.series || [],
        labels: ['Trascurabili', 'Moderate', 'Accentuate', 'Elevate'],
        legend: { show: true },
      },
      setChart: (instance) => { this.chartInstance = instance; }
    };
  }
}
