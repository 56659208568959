/* eslint-disable @typescript-eslint/camelcase */
import { LayoutDataSource, _t } from '@n7-frontend/core';
import {
  Togglers, Toggle, RoadSegmentCollection, RoadSegment
} from 'types';
import * as _set from 'lodash.set';
import * as _has from 'lodash.has';
import * as dayjs from 'dayjs';
import legendConfig, { getColor } from '../../../assets/legend-config';

export class SidebarLayoutDS extends LayoutDataSource {
  private communication;

  public togglers: Togglers = {
    segmentMenu: '',
    activeState: Toggle.CLOSED,
  }

  public getColor;

  public legendConfig;

  public collapsedHeaders: object = {};

  public collapsedSegments: { [id: string]: boolean } = {};

  private loggedIn = false;

  public galleryModal = {
    open: false,
    thumbs: {
      asphalt: [
      ],
      road: [
      ]
    },
    name: '',
    segment: '',
    active: ''
  }

  /**
   * Index of selected road segments.
   */
  public selectedRoads: RoadSegmentCollection = {};

  public labels = (() => {
    const result = {};
    ['iri', 'mpd', 'rutting', 'pci'].forEach((key) => {
      result[key] = _t(key);
    });
    return result;
  })();

  onInit(payload) {
    this.communication = payload.communication;
    this.legendConfig = legendConfig;
    this.getColor = getColor;
    this.loggedIn = payload.loggedIn;
  }

  onSegmentClick(selection) {
    this.selectedRoads = {};
    Object.values(selection).forEach((segment: RoadSegment) => {
      // Reformat the segment's data.
      const asph_tr = segment.properties.asphalt_camera.map((x) => {
        const y = x.replace('rama-s3/', 'https://rama-s3.s3-eu-west-1.amazonaws.com/');
        return y;
      });
      const road_tr = segment.properties.road_camera.map((x) => {
        const y = x.replace('rama-s3/', 'https://rama-s3.s3-eu-west-1.amazonaws.com/');
        return y;
      });
      const prettySegment = {
        geometries: segment.geometry,
        properties: {
          ...segment.properties,
          iri: (+segment.properties.iri).toFixed(2),
          mpd: (+(1.1*segment.properties.mpd)/1000).toFixed(2),
          pci: (+segment.properties.pci).toFixed(2),
          rutting: (+segment.properties.rutting).toFixed(2),
          asphalt_camera: asph_tr,
          road_camera: road_tr,
          date: dayjs(segment.properties.date).locale('it').format('D MMMM YYYY'),
          prettyStrings: {
            name: segment.properties.roads[0],
            segment: `km ${segment.properties.kms_since_starting_point[0]
            } - ${segment.properties.kms_since_starting_point[1]}`
          }
        },
        type: segment.type
      };
      // Build the sidebar
      const {
        county, city, roads, id
      } = segment.properties;
      const [road] = roads;
      const path = `${county}.${city}[${road}].${id}`;
      _set(this.selectedRoads, path, prettySegment);
      if (!_has(this.collapsedSegments, id)) {
        this.collapsedSegments[id] = true;
      }
    });
  }
}
