import { LoginLayoutDS } from './login-layout.ds';
import { LoginLayoutEH } from './login-layout.eh';
import * as DS from '../../data-sources';
import * as EH from '../../event-handlers';

export const LoginLayoutConfig = {
  layoutId: 'rama-login-layout',
  widgets: [
    { id: 'signup', hasStaticData: true },
  ],
  layoutDS: LoginLayoutDS,
  layoutEH: LoginLayoutEH,
  widgetsDataSources: DS,
  widgetsEventHandlers: EH,
  layoutOptions: {},
};
