import { Subject } from 'rxjs';
import { CommunicationService } from '@n7-frontend/boilerplate';

export enum AppState {
  MapOnly,
  MapWithSidebar,
  QuoteWithSidebar,
}

export type Legend = 'iri' | 'mpd' | 'rutting' | 'pci';

export interface LayoutEvent {
  type: string;
  payload: any;
}

export type CommunicationStream = Subject<LayoutEvent>

export interface InitPayload {
  communication?: CommunicationService;
  layoutCommunication$?: CommunicationStream;
}

export enum Toggle { CLOSED, OPEN }

export enum Switch { OFF, ON }

export interface Togglers {
  [x: string]: (Toggle | { [x: string]: Toggle }) | any;
}

export type HTTPHeaders = {
  'access-control-allow-methods'?: string;
  'access-control-allow-origin'?: string;
  'connection'?: string;
  'content-length'?: string;
  'content-location'?: string;
  'content-type'?: string;
  date?: string;
  server?: string;
  tcn?: string;
  vary?: string;
}

export interface HttpResponse {
  body: object;
  headers: HTTPHeaders;
}

export interface SuggestionSource extends HttpResponse {
  body: {
    features: GeoJSONFeature[] | [];
    licence: string;
    type: string;
  };
}

export interface AutocompleteResponse {
  source: SuggestionSource[];
  suggestions: string[];
}

export interface RoadSegmentsResponse {
  features: [] | RoadSegment[];
  type: 'FeatureCollection';
}

export interface RoadSegmentCollection {
  [county: string]: {
    [city: string]: {
      [road: string]: {
        [id: string]: RoadSegment;
      };
    };
  };
}

export type colorConfig = {
  label: string;
  threshold: number;
  color: string;
}

export interface RoadSegment {
  geometry: {
    coordinates: [number, number][];
    type: 'LineString';
  };
  properties: {
    id: string;
    selected?: boolean;
    iri: number;
    mpd: number;
    pci: number;
    rutting: number;
    above: boolean;
    source: string;
    date: string;
    kms_since_starting_point: [number, number];
    length: number;
    roads: string[];
    county: string;
    state: string;
    city: string;
    asphalt_camera: [string];
    road_camera: [string];
  };
  type: 'Feature';
}

export type GeoJSONFeature = {
  bbox?: [number, number, number, number];
  geometry: {
    type: string;
    coordinates: number[];
  };
  properties: {
    category: string;
    display_name: string;
    icon: string;
    importance: number;
    osm_id: number;
    osm_type: string;
    place_id: number;
    place_rank: number;
    type: string;
  };
};

export interface GeoJSON {
  type: 'Feature' | 'FeatureCollection';
  geometry?: {
    type: 'polygon' | 'point' | 'LineString';
    coordinates: [number, number][][];
  };
  features?: any[];
}

export enum AutoCompleteKeyCodes {
  UP = 38,
  DOWN = 40,
  ENTER = 13
}
