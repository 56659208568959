import { SignupData } from '@n7-frontend/components';
import { DataSource } from '@n7-frontend/core';
import { Subject } from 'rxjs';
import SignupConfig from '../config/global/signup.config';

export class SignupDS extends DataSource {
  public screen: string = undefined;

  protected formData = {
    name: undefined,
    email: undefined,
    password: undefined,
    rePassword: undefined
  }

  public actionHandler: Subject<Record<string, any>> = new Subject();

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  protected transform(data): SignupData {
    if (data === undefined) return {};
    return data;
  }

  public initScreen(screenName) {
    this.screen = screenName;
    switch (screenName) {
      case 'login':
        this.update({ login: SignupConfig[screenName] });
        break;
      case 'signup':
        this.update({ signup: SignupConfig[screenName] });
        break;
      case 'forgotPassword':
        this.update({ forgotPassword: SignupConfig[screenName] });
        break;
      default:
        this.screen = 'login';
        this.update({ login: SignupConfig.login });
        break;
    }
  }

  protected updateRemoteErrors(config, payload) {
    const ret = SignupConfig[config];
    // eslint-disable-next-line no-restricted-syntax
    for (const [key] of Object.entries(ret)) {
      ret[key].error = (payload[key] !== undefined) ? payload[key] : undefined;
    }
  }

  public updateFormData(formInput) {
    switch (formInput.inputPayload) {
      case SignupConfig.signup.name.payload:
        this.formData.name = formInput.value;
        break;

      case SignupConfig.login.email.payload:
      case SignupConfig.signup.email.payload:
      case SignupConfig.forgotPassword.email.payload:
        this.formData.email = formInput.value;
        break;

      case SignupConfig.login.password.payload:
      case SignupConfig.signup.password.payload:
        this.formData.password = formInput.value;
        break;

      case SignupConfig.signup.rePassword.payload:
        this.formData.rePassword = formInput.value;
        break;
      default:
        // SILENT
        break;
    }
  }

  public validateLogin() {
    const ret = SignupConfig.login;
    let isValid = true;
    if (!this.checkEmail(this.formData.email)) {
      ret.email.error = 'Indirizzo e-mail non valido';
      isValid = false;
    } else {
      ret.email.error = undefined;
    }
    if (this.formData.password === undefined || this.formData.password.length < 1) {
      ret.password.error = 'Inserire la password';
      isValid = false;
    } else {
      ret.password.error = undefined;
    }
    if (!isValid) {
      this.update({ login: ret });
    } else {
      this.actionHandler.next({
        type: 'login',
        payload: {
          email: this.formData.email,
          password: this.formData.password
        }
      });
    }
  }

  public validateRegister() {
    const ret = SignupConfig.signup;
    let isValid = true;
    if (!this.checkEmail(this.formData.email)) {
      ret.email.error = 'Indirizzo e-mail non valido';
      isValid = false;
    } else {
      ret.email.error = undefined;
    }
    if (!this.checkPassConfirm(this.formData.password, this.formData.rePassword)) {
      ret.rePassword.error = 'Le password non coincidono';
      isValid = false;
    } else {
      ret.rePassword.error = undefined;
    }
    if (!this.checkPass(this.formData.password)) {
      ret.password.error = 'La password non rispetta i vincoli';
      isValid = false;
    } else {
      ret.password.error = undefined;
    }
    if (!isValid) {
      this.update({ signup: ret });
    } else {
      this.actionHandler.next({
        type: 'register',
        payload: {
          name: this.formData.name,
          email: this.formData.email,
          password: this.formData.password
        }
      });
    }
  }

  private checkEmail(address) {
    const emailRgx = /^([a-zA-Z\d_.+-]+)@([\da-zA-Z.-]+)\.([a-zA-Z.]{2,6})$/;
    return emailRgx.test(address);
  }

  private checkPass(pwd) {
    const passRgx = /^(\S{6,10})$/;
    return passRgx.test(pwd);
  }

  private checkPassConfirm(p1, p2) {
    if (p1 === undefined || p2 === undefined) return false;
    if (p1.length < 1) return false;
    if (p2.length < 1) return false;
    return (p1 === p2);
  }
}
