import {
  Component, OnInit, OnDestroy, Input
} from '@angular/core';
import { AbstractLayout, CommunicationService } from '@n7-frontend/boilerplate';
import { CookieService } from 'ngx-cookie-service';
import { Subject } from 'rxjs';
import { SelectionService } from 'src/app/services/selection-service';
import { SidebarLayoutConfig as config } from './sidebar-layout.config';

@Component({
  selector: 'rama-sidebar-layout',
  templateUrl: './sidebar-layout.html'
})
export class SidebarLayoutComponent extends AbstractLayout implements OnInit, OnDestroy {
  @Input() layoutCommunication$: Subject<any>;

  constructor(
    private communication: CommunicationService,
    private selection: SelectionService,
    private cookieService: CookieService,
  ) {
    super(config);
  }

  protected initPayload() {
    const token = (this.cookieService.check('RAMA-LOGIN')) ? this.cookieService.get('RAMA-LOGIN') : undefined;
    return {
      communication: this.communication,
      selection: this.selection,
      layoutCommunication$: this.layoutCommunication$,
      loggedIn: (token !== undefined)
    };
  }

  ngOnInit() {
    this.onInit();
  }

  ngOnDestroy() {
    this.onDestroy();
  }
}
