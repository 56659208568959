import { ServicesLayoutDS } from './services-layout.ds';
import { ServicesLayoutEH } from './services-layout.eh';
import * as DS from '../../data-sources';
import * as EH from '../../event-handlers';

export const ServicesLayoutConfig = {
  layoutId: 'rama-services-layout',
  widgets: [
  ],
  layoutDS: ServicesLayoutDS,
  layoutEH: ServicesLayoutEH,
  widgetsDataSources: DS,
  widgetsEventHandlers: EH,
  layoutOptions: {}
};
