// full dataset: localhost:4469/rama-api/v0/getRoadSegments?from=0&size=10&data_type=original

import { CommunicationService } from '@n7-frontend/boilerplate';
import {
  GeoJSON, RoadSegmentsResponse, RoadSegment,
} from 'types';
import { Map } from 'leaflet';
import 'leaflet.markercluster';
import * as L from 'leaflet';
import { Subject } from 'rxjs';
import legendConfig, { getColor } from '../../assets/legend-config';
import { Legend } from '../../../types';
import { SelectionService } from '../services/selection-service';

/**
 * This class is responsible for the initial creation and updating of the Rama geoJSON layer.
 * @param instance Instance of a Leaflet Map to attach the layer.
 * @param communication Instance of the communication service to request the geoJSON segments.
 *
 * ## LAYER STRUCTURE:
 * Map instance
 * - pointLayer
 * - ramaData
 *   - tileLayers
 *     - featureLayers
 */
class RamaGridLayer {
  /** Draw the tile bounds on the map if set to true */
  tileDebugging = false;

  /** The zoom level where the clustering starts */
  zoomThreshold = 14;

  /** Extension of leaflet's GridLayer Class */
  SegmentsLayer: any;

  /** The layer that renders the geoJSON segments */
  ramaData: L.GeoJSON;

  /**
   * The layer that makes segment requests,
   * visually it does not render anything.
   *
   * Instance of CustomLayerClass
   */
  segmentsLayer: L.GridLayer;

  /**
   * The layer that holds the geoJSON points (not segments!).
   * It loads the markers & clusters.
   */
  pointsLayer: L.Layer;

  layerStorage: object = {};

  selectedSegments: object = {};

  /** Currently selected legend */
  selectedLegend: Legend = 'iri';

  /** Currently selected dataSet */
  selectedData: 'base' | 'pro' = 'base';

  /** Query used to fetch segments */
  queryName: 'getSegmentsBox' | 'getSegmentsBoxPro';

  /** Default segment thickness */
  weight = 6;

  /** Event emitter */
  event$: Subject<{ type: string; payload: object }> = new Subject();

  /** Instance of leaflet's map object */
  mapInstance: Map;

  selectionService: SelectionService;

  /**
   * Always has the updated selection,
   * this way the style can be evaluated for each segment
   * without spamming requests to the Rx observable.
   * ( see RamaStyle )
  */
  currentSelection: { [id: string]: RoadSegment };

  /**
   * Configuration for color styles.
   */
  private config = legendConfig;

  async init(instance: Map, services: any) {
    const { communication, selection } = services;
    // Create panes, to control which segment is rendered in front of others.
    const paneFront = instance.createPane('rama-front');
    const paneBack = instance.createPane('rama-back');
    paneFront.style.zIndex = '401';
    paneBack.style.zIndex = '400';

    this.ramaData = L.geoJSON();
    this.mapInstance = instance;

    this.selectionService = selection;
    this.selectionService.selection$.subscribe((state) => {
      this.currentSelection = state;
    });
    this.listenSelections(); // start listening for selection updates

    // Dynamically switch query url between basic and pro versions.
    if (this.selectedData === 'base') {
      this.queryName = 'getSegmentsBox';
    } else {
      this.queryName = 'getSegmentsBoxPro';
    }

    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const self = this;
    instance.addEventListener('zoomend', (e) => self.handleMapZoom(e));

    let cookies: Array<string> = document.cookie.split(';');
    let groups = '';
    let api_key = '';
    let email = '';
    for(let i = 0; i < cookies.length; i++){
        let c = cookies[i].trim();
        if(c.startsWith("groups") && groups == ''){
            var a = c.split("=");
            groups = a[1];
        }
        if(c.startsWith("api_key") && api_key == ''){
            var a = c.split('=');
            api_key = a[1];
        }
        if(c.startsWith("email") && email == ''){
          var a = c.split('=');
          email = a[1];
      }
    }

    let clientInfo = {};
    if(api_key !== "" && email !== ""){
      let resp = await fetch("https://rama-italy.it/rama/clientInfo/?username="+email+"&api_key="+api_key, {
        method: 'POST'
      }).then(response => response.json());
        clientInfo = resp;
    }
    else{
      clientInfo = {bulkdata: []};
    }
    
    // Pre-load the marker cluster layer.
    this.loadClusterPoints(communication, clientInfo);

    this.SegmentsLayer = L.GridLayer.extend({
      createTile(coords: { x: number; y: number; z: number }, done): HTMLElement {
        const tile = document.createElement('span');
        if (self.tileDebugging) tile.style.outline = '1px solid #FF4136';
        const bounds = this._tileCoordsToBounds(coords);
        const { _northEast: tr, _southWest: bl } = bounds;
        const tl = { lat: tr.lat, lng: bl.lng };
        const br = { lat: bl.lat, lng: tr.lng };
        let params = {
          "geodata": self.geoJSONfromPoints([tl, tr, br, bl, tl]),
          "clientInfo": clientInfo
        }
        communication.request$(self.queryName, {
          method: 'POST',
          params: params,
          onError: (e) => console.error(e)
        }).subscribe((res: RoadSegmentsResponse) => {
          const newLayer = L.layerGroup();
          // Add the feature collection to a new layer
          L.geoJSON(res, {
            onEachFeature: (feature) => {
              const isAbove = feature.properties.above;
              newLayer.addLayer(L.geoJSON(feature, {
                // Attach the layer to the correct pane
                pane: isAbove ? 'rama-front' : 'rama-back',
                style: self.ramaStyle.bind(self)
                // listen for clicks on the segments
              }).addEventListener('click', (e) => self.handleSegmentClick(e)));
            },
          });
          // Add the new layer to the parent layer (layer group)
          self.ramaData.addLayer(newLayer);
          // create an id to access the new layer later (when unloading it)
          self.layerStorage[`${coords.x}_${coords.y}_${coords.z}`] = newLayer;
          // return a dummy tile to leaflet's callback
          done(null, tile);
        });
        return tile;
      }
    });
    // Create a Rama grid layer
    this.segmentsLayer = ((opts?) => new this.SegmentsLayer(opts))()
      .addEventListener('tileunload', (e) => { this.flushTile(e); });
    // Add the "dummy" layer to the map instance
    instance.addLayer(this.segmentsLayer);
    if (instance.getZoom() >= this.zoomThreshold) {
      this.ramaData.addTo(instance);
    }
  }

  private listenSelections() {
    this.selectionService.selection$.subscribe(() => {
      this.reloadStyle();
    });
  }

  /**
   * Updates the segment style according to the selected legend.
   * @param key Map legend string.
   */
  public selectLegend(key: Legend) {
    this.selectedLegend = key;
    this.reloadStyle();
  }

  /**
   * Changes the dataset and reloads the segment layer
   * @param type dataset type
   */
  public changeDataset(type: 'base' | 'pro') {
    this.selectedData = type;
    this.queryName = type === 'base' ? 'getSegmentsBox' : 'getSegmentsBoxPro';
    this.ramaData.eachLayer((layer) => { layer.remove(); });
    this.segmentsLayer.redraw();
  }

  /**
   * Requests all cluster points and adds the layer to the map.
   * @param communication Communication provider to call for requests.
   */

  private loadClusterPoints(communication: CommunicationService, clientInfo) {    
    communication.request$('getClusters', {
      method: 'POST',
      params: clientInfo,
      onError: (e) => console.error(e)
    }).subscribe((res) => {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { cities } = res;
      const markerGroup = L.markerClusterGroup(); // create a cluster layer
      markerGroup.addLayer(L.geoJSON(cities, {
        pointToLayer: (feature, latlng) => L.marker(latlng, {
          icon: L.icon({
            iconUrl: '/assets/cluster.png',
            iconSize: [40, 40]
          })
        })
      })); // add geoJSON layer to cluster layer
      this.pointsLayer = markerGroup; // save the cluster layer for later use
      // if the map is zoomed out, also add the point layer to the map
      if (this.mapInstance.getZoom() < this.zoomThreshold) {
        this.pointsLayer.addTo(this.mapInstance);
      }
    });
  }

  /**
   * Event handler for leaflet map zoom.
   * @param e Layer event object
   */
  private handleMapZoom(e: L.LeafletEvent): any {
    const zoom = e.target.getZoom();
    if (zoom < this.zoomThreshold) {
      // Exit the segments view
      this.segmentsLayer.remove();
      this.ramaData.remove();
      this.pointsLayer.addTo(this.mapInstance);
    } else {
      // Enter the segments view
      this.pointsLayer.remove();
      if (!this.mapInstance.hasLayer(this.ramaData)) {
        this.segmentsLayer.addTo(this.mapInstance);
        this.ramaData.addTo(this.mapInstance);
      }
    }

    this.event$.next({ type: 'zoomend', payload: { target: e.target } });
  }

  /**
   * Adds or removes the selected segment to the selectedLayers object.
   * selectedLayers[id] = { segmentData }
   * @param e Click event
   */
  handleSegmentClick(e: L.LeafletEvent): L.LeafletEvent {
    if ('feature' in e.propagatedFrom) {
      const segment: RoadSegment = e.propagatedFrom.feature;
      const ID = segment.properties.id;

      if (this.selectionService.getValue()[ID]) {
        this.selectionService.delete(ID);
        if (Object.keys(this.selectionService.getValue()).length === 0) {
          this.event$.next({ type: 'closesidebar', payload: {} });
        }
      } else {
        const newSelection = [];
        newSelection[ID] = segment;
        this.selectionService.update(newSelection);
        if (Object.keys(this.selectionService.getValue()).length >= 1) {
          this.event$.next({ type: 'opensidebar', payload: {} });
        }
      }
      this.event$.next({ type: 'segmentclick', payload: { segment } });
    }
    return e;
  }

  /**
   * Unloads all of the segments in a tile and removes the tile from the layer.
   * @param param0 Tile event object
   */
  flushTile({ coords }) {
    if (this.layerStorage[this.coordsToID(coords)]) {
      this.layerStorage[this.coordsToID(coords)].clearLayers();
      this.ramaData.removeLayer(this.layerStorage[this.coordsToID(coords)]);
    }
  }

  /**
   * Refreshes the syle of each rama segment.
   * Must be called after changing the style function.
   */
  reloadStyle = () => {
    this.ramaData.eachLayer((tile: L.LayerGroup) => {
      tile.eachLayer((geoJSON: L.GeoJSON) => {
        geoJSON.setStyle(this.ramaStyle.bind(this));
      });
    });
  }

  /**
   * Converts an array of lat, long tuples into a geoJSON object
   * @param points array of lat, lng points to convert in feature
   */
  geoJSONfromPoints = (points: { lat: number; lng: number }[]): GeoJSON => ({
    type: 'Feature',
    geometry: {
      type: 'polygon',
      coordinates: [
        points.map((p) => ([p.lng, p.lat]))
      ]
    }
  });

  /**
   * Creates a unique UI for each tile based on it's coordinates.
   * @param param0 Coords object
   */
  coordsToID({ x, y, z }) { return (`${x}_${y}_${z}`); }

  /**
   * Leaflet's style function applied to each geoJSON object
   * @param feature a geoJSON object
   */
  ramaStyle(feature: RoadSegment): L.PathOptions {
    const isAbove = feature.properties.above;
    // Style for SELECTED segments
    // use this.currentSelection instead of this.selectionService.getValue() ( better performance )
    if (this.currentSelection[feature.properties.id]) {
      return {
        lineCap: 'butt',
        color: this.config.selected,
        weight: isAbove ? this.weight : this.weight - 1
      };
    }
    const l = this.selectedLegend.toLocaleLowerCase();
    const n = feature.properties[this.selectedLegend];
    return {
      lineCap: 'butt',
      color: getColor(l as Legend, n),
      weight: isAbove ? this.weight : this.weight - 1,
    };
  }
}

export { RamaGridLayer };
