import { PersonalLayoutDS } from './personal-layout.ds';
import { PersonalLayoutEH } from './personal-layout.eh';
import * as DS from '../../data-sources';
import * as EH from '../../event-handlers';

export const PersonalLayoutConfig = {
  layoutId: 'rama-personal-layout',
  widgets: [
  ],
  layoutDS: PersonalLayoutDS,
  layoutEH: PersonalLayoutEH,
  widgetsDataSources: DS,
  widgetsEventHandlers: EH,
  layoutOptions: {}
};
