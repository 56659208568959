export default {
  pathToScreen: {
    '/login': 'login',
    '/signup': 'signup',
    '/forgot': 'forgotPassword'
  },
  login: {
    email: {
      classes: 'email-extra-class',
      id: 'login-email',
      label: 'Email',
      inputType: 'email',
      payload: 'login-email',
      error: undefined
    },
    password: {
      id: 'login-pwd',
      label: 'Password',
      inputType: 'password',
      show: {
        icon: 'far fa-eye',
        label: 'Mostra password',
        payload: 'login-show-pwd',
      },
      payload: 'login-pwd',
      error: undefined
    },
    /* forgotPassword: {
      title: 'vai a recupero password',
      label: 'Password dimenticata?',
      payload: 'forgot-pwd',
    }, */
    submit: {
      classes: 'rm-login__input-button',
      label: 'Entra',
      payload: 'login-submit',
      error: undefined,
    },
    /* footer: {
      label: 'Non hai un account?',
      action: {
        label: 'Registrati',
        payload: 'go-signup'
      }
    } */
  },
  signup: {
    name: {
      id: 'signup-name',
      label: 'Nome e cognome',
      inputType: 'text',
      payload: 'signup-name',
      error: undefined
    },
    email: {
      id: 'signup-email',
      label: 'Email',
      inputType: 'email',
      payload: 'signup-email',
      error: undefined
    },
    password: {
      id: 'signup-pwd',
      label: 'Password',
      inputType: 'password',
      hint: 'La password deve avere fra i 6 e i 10 caratteri e contenere almeno una lettera maiuscola',
      show: {
        icon: 'n7-icon-eye',
        label: 'Mostra password',
        payload: 'signup-show-pwd',
      },
      payload: 'signup-pwd',
      error: undefined
    },
    rePassword: {
      id: 'signup-repwd',
      label: 'Ripeti Password',
      inputType: 'password',
      payload: 'signup-repwd',
      error: undefined
    },
    submit: {
      label: 'Registrati',
      payload: 'signup-submit',
      error: undefined
    },
    footer: {
      label: 'Hai già un account?',
      action: {
        label: 'Entra',
        payload: 'go-login'
      }
    }
  },
  forgotPassword: {
    description: 'Inserisci l\'indirizzo email lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam vitae lacus eleifend, vestibulum neque nec, dictum eros. Nunc vel risus leo',
    email: {
      id: 'forgot-password-email',
      label: 'Email',
      inputType: 'email',
      payload: 'forgotPassword-email',
      error: undefined
    },
    submit: {
      label: 'Reset password',
      payload: 'forgotPassword-submit',
      error: undefined
    },
    footer: {
      label: 'Entra nella piattaforma',
      action: {
        label: 'Entra',
        payload: 'go-app'
      }
    }
  }
};
