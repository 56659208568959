/* eslint-disable @typescript-eslint/camelcase */
import { CommunicationService } from '@n7-frontend/boilerplate';
import { HttpHeaders } from '@angular/common/http';
import { EventHandler } from '@n7-frontend/core';
import { CookieService } from 'ngx-cookie-service';
import { SelectionService } from 'src/app/services/selection-service';

export class PersonalLayoutEH extends EventHandler {
  private communication: CommunicationService;

  private cookieService: CookieService;

  private selectionService: SelectionService;

  public listen() {
    this.innerEvents$.subscribe(({ type, payload }) => {
      switch (type) {
        case 'rama-personal-layout.init':
          this.listenRouter();
          this.communication = payload.communication;
          this.cookieService = payload.cookieService;
          this.selectionService = payload.selectionService;
          this.dataSource.onInit(payload);
          break;
        case 'rama-personal-layout.tab-change':
          this.dataSource.activeTab = payload;
          break;
        case 'rama-personal-layout.loadproj':
          this.loadProject(payload);
          break;
        default:
          // console.log(type, payload);
          break;
      }
    });

    // this.outerEvents$.subscribe(({ type, payload }) => {
    //   switch (type) {
    //     default:
    //       break;
    //   }
    // });
  }

  public listenRouter() {
    this.dataSource.routerNotifier.subscribe((el) => {
      this.emitGlobal('navigate', {
        handler: 'router',
        path: [el],
      });
    });
  }

  protected loadProject(id) {
    const token = (this.cookieService.check('RAMA-LOGIN')) ? this.cookieService.get('RAMA-LOGIN') : undefined;
    if (token !== undefined) {
      this.communication.request$('getUserProjectSingle', {
        urlParams: id,
        method: 'GET',
        params: {},
        httpOptions: {
          headers: new HttpHeaders({ Authorization: `Bearer ${token}` })
        },
        onError: (err) => {
          console.warn('err', err);
        }
      }).subscribe((response) => {
        const selectionState = {};
        response.selected_segments.features.forEach((el) => {
        //   // In backend sono in maiuscolo, e manca il PCI
        //   el.properties.iri = el.properties.IRI;
        //   el.properties.mpd = el.properties.MPD;
        //   el.properties.pci = -1;
          selectionState[el.properties.id] = el;
        });
        // true = readOnly
        this.selectionService.update(selectionState, true);
        this.emitGlobal('navigate', {
          handler: 'router',
          path: ['app'],
        });
      });
    } else {
      this.emitGlobal('navigate', {
        handler: 'router',
        path: ['login'],
      });
    }
  }
}
