import { EventHandler } from '@n7-frontend/core';

export class InnerTitleEH extends EventHandler {
  public listen() {
    this.innerEvents$.subscribe(({ type, payload }) => {
      switch (type) {
        case 'inner-title.click':
          this.emitOuter('clickmenu', payload);
          break;
        case 'inner-title.collapse':

          this.emitOuter('collapse', payload);
          break;
        default:
          break;
      }
    });
  }
}
