import { BrowserModule } from '@angular/platform-browser';
import { NgModule, APP_INITIALIZER } from '@angular/core';
import { RouterModule } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { translate } from '@n7-frontend/core';
import {
  N7BoilerplateCommonModule,
  JsonConfigService,
  LocalConfigService
} from '@n7-frontend/boilerplate';
import { DvComponentsLibModule } from '@n7-frontend/components';
import { CookieService } from 'ngx-cookie-service';
import globalConfig from './config/global';
import { APP_ROUTES } from './app.routes';

import { AppComponent } from './app.component';
import { AppLayoutComponent } from './layouts/app-layout/app-layout';
import { HomeLayoutComponent } from './layouts/home-layout/home-layout';
import { LoginLayoutComponent } from './layouts/login-layout/login-layout';
import { MapLayoutComponent } from './layouts/map-layout/map-layout';
import { QuoteLayoutComponent } from './layouts/quote-layout/quote-layout';
import { SidebarLayoutComponent } from './layouts/sidebar-layout/sidebar-layout';
import { PersonalLayoutComponent } from './layouts/personal-layout/personal-layout';
import { SelectionService } from './services/selection-service';

import i18n from './config/i18n';
import { LogoutComponent } from './logout/logout.component';

// load translations
translate.init({
  defaultLang: 'it_IT',
  translations: i18n
});

const JSON_PATH = '/assets/app-config.json';

@NgModule({
  declarations: [
    AppComponent,
    AppLayoutComponent,
    HomeLayoutComponent,
    LoginLayoutComponent,
    MapLayoutComponent,
    QuoteLayoutComponent,
    SidebarLayoutComponent,
    PersonalLayoutComponent,
    LogoutComponent
  ],
  imports: [
    BrowserModule,
    RouterModule.forRoot(
      APP_ROUTES
    ),
    N7BoilerplateCommonModule.forRoot({}),
    DvComponentsLibModule,
    FormsModule
  ],
  providers: [
    SelectionService,
    CookieService,
    {
      provide: APP_INITIALIZER,
      useFactory: (
        localConfigService: LocalConfigService,
        jsonConfigService: JsonConfigService
      ) => () => (
        localConfigService.load(globalConfig)
          .then(() => jsonConfigService.load(JSON_PATH))
      ),
      deps: [LocalConfigService, JsonConfigService],
      multi: true
    }],
  bootstrap: [AppComponent]
})
export class AppModule { }
