import {
  Component, OnInit, OnDestroy, Input, HostListener
} from '@angular/core';
import { AbstractLayout, CommunicationService } from '@n7-frontend/boilerplate';
import { Subject } from 'rxjs';
import { SelectionService } from 'src/app/services/selection-service';
import { MapLayoutConfig as config } from './map-layout.config';
import { AutoCompleteKeyCodes } from '../../../../types';

@Component({
  selector: 'rama-map-layout',
  templateUrl: './map-layout.html'
})
export class MapLayoutComponent extends AbstractLayout implements OnInit, OnDestroy {
  @Input() layoutCommunication$: Subject<any>;

  @HostListener('window:keyup', ['$event'])
  keyEvent(event: KeyboardEvent) {
    const { keyCode } = event;
    if ([
      AutoCompleteKeyCodes.UP,
      AutoCompleteKeyCodes.DOWN,
      AutoCompleteKeyCodes.ENTER
    ].includes(keyCode)) {
      this.layoutCommunication$.next({
        type: 'map-layout.autocompletekeyup',
        payload: { keyCode }
      });
    }
  }

  constructor(
    private communication: CommunicationService,
    private selectionService: SelectionService,
  ) {
    super(config);
  }

  protected initPayload() {
    return {
      communication: this.communication,
      layoutCommunication$: this.layoutCommunication$,
      selectionService: this.selectionService
    };
  }

  ngOnInit() {
    this.onInit();
  }

  ngOnDestroy() {
    this.onDestroy();
  }
}
