import { Component, OnInit, OnDestroy } from '@angular/core';
import {
  AbstractLayout, CommunicationService, ConfigurationService, MainStateService
} from '@n7-frontend/boilerplate';
import { CookieService } from 'ngx-cookie-service';
import { Subject } from 'rxjs';
import { LayoutEvent, InitPayload } from 'types';
import { AppLayoutConfig as config } from './app-layout.config';

@Component({
  selector: 'rama-app-layout',
  templateUrl: './app-layout.html'
})
export class AppLayoutComponent extends AbstractLayout implements OnInit, OnDestroy {
  public layoutCommunication$: Subject<LayoutEvent> = new Subject()

  constructor(
    private communication: CommunicationService,
    private configuration: ConfigurationService,
    private mainState: MainStateService,
    private cookieService: CookieService,
  ) {
    super(config);
  }

  protected initPayload(): InitPayload {
    const token = (this.cookieService.check('RAMA-LOGIN')) ? this.cookieService.get('RAMA-LOGIN') : undefined;
    if (token !== undefined) {
      const topbar = this.configuration.get('header');
      topbar.user = {
        name: 'Area personale',
        anchor: { href: '/personal' }
      };
      topbar.buttons = [{text: "Esci", anchor: {href:"/logout"}, classes: "n7-btn"}];
      this.mainState.update('header', topbar);
    }
    return {
      communication: this.communication,
      layoutCommunication$: this.layoutCommunication$
    };
  }

  ngOnInit() {
    this.onInit();
  }

  ngOnDestroy() {
    this.onDestroy();
  }
}
