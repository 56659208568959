import { Legend } from 'types';

const legendConfig: {
  fallback: string;
  selected: string;
  labels: { [indicator: string]: string };
  categories: any;
  colors: {
    [indicator: string]: {
      label: string;
      color: string;
      threshold: number;
    }[];
  };
} = {
  fallback: '#ddd',
  selected: '#3CC2AF',
  labels: {
    iri: 'IRI',
    mpd: 'Macrotessitura',
    rutting: 'Ormaiamento'
    //pci: 'ICP'
  },
  categories: {
    iri: {
      Trascurabili: 0,
      Moderate: 0,
      Accentuate: 0,
      Elevate: 0,
    },
    mpd: {
      Trascurabile: 0,
      Moderata: 0,
      Accentuata: 0,
      Elevata: 0,
    },
    rutting: {
      Trascurabile: 0,
      Moderata: 0,
      Accentuata: 0,
      Elevata: 0,
    },
    pci: {
      Pessimo: 0,
      'Molto Scadente': 0,
      Scadente: 0,
      Discreto: 0,
      Buono: 0,
      'Molto Buono': 0,
      Eccellente: 0,
    },
  },
  colors: {
    iri: [
      { label: 'Trascurabili', color: '#AFD336', threshold: 2.5 },
      { label: 'Moderate', color: '#FFE746', threshold: 3.5 },
      { label: 'Accentuate', color: '#F89D14', threshold: 4.5 },
      { label: 'Elevate', color: '#CC2D2D', threshold: Infinity },
    ],
    mpd: [
      { label: 'Trascurabile', color: '#D3E4F3', threshold: 350 },
      { label: 'Moderata', color: '#5CA3D0', threshold: 500 },
      { label: 'Accentuata', color: '#2270B3', threshold: 650 },
      { label: 'Elevata', color: '#08326E', threshold: Infinity },
    ],
    rutting: [
      { label: 'Trascurabile', color: '#FEDDBD', threshold: 1 },
      { label: 'Moderata', color: '#FD9B50', threshold: 2 },
      { label: 'Accentuata', color: '#D84D05', threshold: 3 },
      { label: 'Elevata', color: '#852904', threshold: Infinity },
    ],
    pci: [
      { label: 'Pessimo', color: '#808080', threshold: 0 },
      { label: 'Molto Scadente', color: '#870606', threshold: 10 },
      { label: 'Scadente', color: '#FF0101', threshold: 25 },
      { label: 'Discreto', color: '#FFC013', threshold: 40 },
      { label: 'Buono', color: '#FFFF1D', threshold: 55 },
      { label: 'Molto Buono', color: '#92D050', threshold: 70 },
      { label: 'Eccellente', color: '#006405', threshold: Infinity },
    ],
  }
};

export function getColor(legend: Legend, value: number): string {
  const index = legendConfig.colors[legend].findIndex((d) => d.threshold > value);
  if (index > -1) {
    return legendConfig.colors[legend][index].color;
  } return legendConfig.fallback;
}

export function getLabel(legend: Legend, value: number): string {
  const index = legendConfig.colors[legend].findIndex((d) => d.threshold > value);
  if (index > -1) {
    return legendConfig.colors[legend][index].label;
  } return '';
}

export default legendConfig;
