import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
@Injectable()
export class SelectionService {
  selection$ = new BehaviorSubject({});

  private isReadOnly = false;

  /** Updates the state with new values */
  update(param: object, readonly = false) {
    this.selection$.next({
      ...this.selection$.getValue(),
      ...param
    });
    this.isReadOnly = readonly;
  }

  public readonly() {
    return this.isReadOnly;
  }

  /** Delete a property from the state */
  delete(param) {
    const value = this.selection$.getValue();
    delete value[param];
    this.selection$.next(value);
  }

  /** Emits the current state */
  getValue() {
    return this.selection$.getValue();
  }
}
