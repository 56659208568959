import { MapData } from '@n7-frontend/components';
import { DataSource } from '@n7-frontend/core';
import { Map } from 'leaflet';
import * as leaflet from 'leaflet';
import { GeoJSONFeature } from 'types';
import { RamaGridLayer } from '../models/rama-tileset';

export class MapDS extends DataSource {
  private instance: Map;

  private RamaLayer = new RamaGridLayer();

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  protected transform(data): MapData {
    return {
      containerId: 'map-canvas',
      tileLayers: [{
        url: 'https://api.maptiler.com/maps/positron/{z}/{x}/{y}.png?key=E5PiDObvEB6LRNVqER1a',
        options: {
          maxZoom: 18,
          attribution: '<a href="https://www.maptiler.com/copyright/" target="_blank">&copy; MapTiler</a> <a href="https://www.openstreetmap.org/copyright" target="_blank">&copy; OpenStreetMap contributors</a>'
        },
      }],
      initialView: {
        // Center map on EMPOLI
        center: [43.60625069174644, 10.805740356445314],
        zoom: 10,
      },
      _setInstance: (i: Map) => {
        this.instance = i;
        i.zoomControl.remove();
        leaflet.control.zoom({ position: 'bottomright' }).addTo(i);
        this.RamaLayer.init(i, {
          communication: this.options.communication,
          selection: this.options.selection,
        });
        this.RamaLayer.selectLegend('iri');
      }
    };
  }

  /**
  * Moves the place into view
  * @param place a geoJSON feature object
  */
  public moveMap = (place: GeoJSONFeature) => {
    const bb = place.bbox;
    const topLeft = leaflet.latLng([bb[1], bb[0]]);
    const bottomRight = leaflet.latLng([bb[3], bb[2]]);
    const bounds = leaflet.latLngBounds(topLeft, bottomRight);
    this.instance.fitBounds(bounds);
  }
}
