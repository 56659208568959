import { EventHandler } from '@n7-frontend/core';

export class SignupEH extends EventHandler {
  public listen() {
    this.innerEvents$.subscribe(({ type, payload }) => {
      switch (type) {
        case 'signup.click':
          this.handleClick(payload);
          break;
        case 'signup.change':
          this.dataSource.updateFormData(payload);
          break;
        case 'signup.focus':
        case 'signup.focusout':
          // SILENT
          break;
        case 'signup.enter':
          if (this.dataSource.screen === 'login') {
            this.dataSource.validateLogin();
          }
          if (this.dataSource.screen === 'signup') {
            this.dataSource.validateRegister();
          }
          break;
        default:
          // console.log(type, payload);
          break;
      }
    });

    this.outerEvents$.subscribe(({ type, payload }) => {
      switch (type) {
        case 'rama-login-layout.initscreen':
          this.dataSource.initScreen(payload);
          this.dataSource.actionHandler.subscribe((ev) => {
            this.handleEvents(ev);
          });
          break;
        case 'rama-login-layout.error_login':
          this.dataSource.updateRemoteErrors('login', payload);
          break;
        case 'rama-login-layout.error_register':
          this.dataSource.updateRemoteErrors('signup', payload);
          break;
        default:
          // console.log(type, payload);
      }
    });
  }

  public handleClick(payload) {
    switch (payload) {
      case 'login-submit':
        this.dataSource.validateLogin();
        break;
      case 'signup-submit':
        this.dataSource.validateRegister();
        break;
      case 'login-show-pwd':
        this.togglePasswordField('login-pwd');
        break;
      case 'signup-show-pwd':
        this.togglePasswordField('signup-pwd');
        break;
      case 'go-login':
        window.location.href = 'login';
        break;
      default:
        console.warn('Unhandled submit', payload);
        break;
    }
  }

  protected togglePasswordField(id) {
    const el = document.getElementById(id);
    if (el.getAttribute('type') === 'password') {
      el.setAttribute('type', 'text');
    } else {
      el.setAttribute('type', 'password');
    }
  }

  protected handleEvents({ type, payload }) {
    switch (type) {
      case 'login':
        this.emitOuter('login', payload);
        break;
      case 'register':
        this.emitOuter('register', payload);
        break;
      default:
        break;
    }
  }
}
