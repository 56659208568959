import { AboutLayoutDS } from './about-layout.ds';
import { AboutLayoutEH } from './about-layout.eh';
import * as DS from '../../data-sources';
import * as EH from '../../event-handlers';

export const AboutLayoutConfig = {
  layoutId: 'rama-about-layout',
  widgets: [
  ],
  layoutDS: AboutLayoutDS,
  layoutEH: AboutLayoutEH,
  widgetsDataSources: DS,
  widgetsEventHandlers: EH,
  layoutOptions: {}
};
