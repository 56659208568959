import { Component, OnInit } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';

@Component({
  selector: 'rama-logout',
  templateUrl: './logout.component.html',
  styleUrls: ['./logout.component.scss']
})
export class LogoutComponent implements OnInit {

  constructor(private cookieService: CookieService) { }

  ngOnInit(): void {
    const token = (this.cookieService.check('RAMA-LOGIN')) ? this.cookieService.get('RAMA-LOGIN') : undefined;
      if (token !== undefined) {
        this.cookieService.deleteAll();
        location.href = "/app"
      }
  }

}
