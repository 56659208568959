import { EventHandler } from '@n7-frontend/core';
import { AppState } from 'types';

export class AppLayoutEH extends EventHandler {
  private layoutCommunication$;

  public listen() {
    this.innerEvents$.subscribe(({ type, payload }) => {
      switch (type) {
        case 'rama-app-layout.init':
          this.dataSource.onInit(payload);
          this.layoutCommunication$ = payload.layoutCommunication$;
          this.listenLayouts();
          break;
        default:
          break;
      }
    });
  }

  listenLayouts = () => {
    this.layoutCommunication$.subscribe(({ type }) => {
      switch (type) {
        case 'sidebar-layout.finish':
          this.dataSource.layoutState = AppState.QuoteWithSidebar;
          break;
        case 'quote-layout.goback':
          this.dataSource.layoutState = AppState.MapWithSidebar;
          break;
        case 'sidebar-layout.opensidebar':
        case 'map-layout.opensidebar':
          this.dataSource.layoutState = AppState.MapWithSidebar;
          break;
        case 'sidebar-layout.closesidebar':
        case 'map-layout.closesidebar':
          this.dataSource.layoutState = AppState.MapOnly;
          break;
        default:
          break;
      }
    });
  };
}
