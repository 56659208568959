import { LayoutDataSource } from '@n7-frontend/core';
import { first } from 'rxjs/operators';
import { SelectionService } from 'src/app/services/selection-service';
import { RoadSegment, RoadSegmentsResponse, Legend } from 'types';
import { chartMock } from './mocks';
import legendConfig, { getLabel } from '../../../assets/legend-config';

export class QuoteLayoutDS extends LayoutDataSource {
  private communication;

  private selectionService: SelectionService;

  public chartMock = chartMock;

  private order: Legend[] = ['iri', 'mpd', 'pci', 'rutting'];

  private currentOrder = 0;

  public activeCategory = this.order[this.currentOrder];

  public legendConfig;

  /** Total amount of selected segments */
  totalSegments: string;

  /** Total kilometers of selected roads */
  totalRoadLength: string;

  estimate = {
    low: '',
    high: ''
  }

  onInit(payload) {
    this.communication = payload.communication;
    this.selectionService = payload.selectionService;
    this.legendConfig = legendConfig;
    this.onSelectionChange();
  }

  handlePropertyChange(inc = true) {
    if (inc) {
      if (this.currentOrder === (this.order.length - 1)) this.currentOrder = 0;
      else this.currentOrder += 1;
    } else if (this.currentOrder === 0) this.currentOrder = (this.order.length - 1);
    else this.currentOrder -= 1;
    this.activeCategory = this.order[this.currentOrder];
    this.onSelectionChange(this.order[this.currentOrder]);
  }

  onSelectionChange(property: Legend = 'iri') {
    this.selectionService.selection$.subscribe((selection) => {
      const segmentCount = Object.keys(selection).length;
      this.totalSegments = `${segmentCount}`;
      this.totalRoadLength = `${(50 * segmentCount) / 1000} km`;

      const requestBody: RoadSegmentsResponse = {
        type: 'FeatureCollection',
        features: Object.values(selection)
      };

      const counter: Record<string, any> = legendConfig.categories[property];

      Object.values(selection).forEach((segment: RoadSegment) => {
        const label = getLabel(property, segment.properties[property]);
        counter[label] += 1;
      });

      const series = Object.values(counter).map((d) => (d / segmentCount) * 100);
      const colors = [];
      const labels = [];
      legendConfig.colors[property].forEach((el) => {
        colors.push(el.color);
        labels.push(el.label);
      });

      // Update the data series of the donut chart
      if (!this.getWidgetDataSource('donut-chart').chartInstance) {
        this.one('donut-chart').update({ series, colors, labels });
      } else {
        this.getWidgetDataSource('donut-chart').chartInstance.updateSeries(series);
        this.getWidgetDataSource('donut-chart').chartInstance.updateOptions({
          colors,
          fill: {
            colors
          },
          labels
        });
      }

      if (Object.values(selection).length > 0) {
        this.communication.request$('costEstimate', {
          method: 'POST',
          params: requestBody,
          onError: (e) => console.error(e)
        }).pipe(first()).subscribe((res) => {
          this.estimate.low = `${res.min_cost / 1000}`;
          this.estimate.high = `${res.max_cost / 1000}`;
        });
      } else {
        // if nothing is selected
        this.estimate = { low: '0', high: '0' };
      }
    });
  }
}
