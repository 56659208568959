import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {
  AbstractLayout, CommunicationService, ConfigurationService, MainStateService
} from '@n7-frontend/boilerplate';
import { CookieService } from 'ngx-cookie-service';
import { LoginLayoutConfig as config } from './login-layout.config';

@Component({
  selector: 'rama-login-layout',
  templateUrl: './login-layout.html'
})
export class LoginLayoutComponent extends AbstractLayout implements OnInit, OnDestroy {
  constructor(
    private communication: CommunicationService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private cookieService: CookieService,
    private configuration: ConfigurationService,
    private mainState: MainStateService
  ) {
    super(config);
  }

  protected initPayload() {
    return {
      communication: this.communication,
      router: this.router,
      activatedRoute: this.activatedRoute,
      cookieService: this.cookieService,
      configuration: this.configuration,
      mainState: this.mainState,
    };
  }

  ngOnInit() {
    this.onInit();
  }

  ngOnDestroy() {
    this.onDestroy();
  }
}
