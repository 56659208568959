import { AppLayoutDS } from './app-layout.ds';
import { AppLayoutEH } from './app-layout.eh';
import * as DS from '../../data-sources';
import * as EH from '../../event-handlers';

export const AppLayoutConfig = {
  layoutId: 'rama-app-layout',
  widgets: [
    // TODO
  ],
  layoutDS: AppLayoutDS,
  layoutEH: AppLayoutEH,
  widgetsDataSources: DS,
  widgetsEventHandlers: EH,
  layoutOptions: {}
};
