const chartMock = {
  containerId: 'test-Chart',
  libOptions: {
    chart: {
      type: 'donut',
      height: 100,
    },
    dataLabels: { enabled: false },
    colors: ['#AFD336', '#FFE746', '#F89D14', '#CC2D2D'],
    fill: {
      colors: ['#AFD336', '#FFE746', '#F89D14', '#CC2D2D'],
    },
    plotOptions: {
      pie: {
        donut: {
          size: '78%'
        }
      }
    },
    series: [],
    labels: ['Trascurabili', 'Moderate', 'Accentuate', 'Elevate'],
    legend: { show: true },
  }
};

export { chartMock };
